import React, { useState } from 'react'
import { Navbar, NavLink } from 'reactstrap'
import { useCoreContext } from '../../hooks/contexts/coreProvider'
import { useLocalStorage, useTheme } from '../../hooks'
import * as S from './styles'
import Logo from '../../theme/logo'
import Fitas from '../../theme/fitas'
import { useFamilyQuery, useGetImageBYFamilyId } from '../../queries/family/queries'
import { useGetFamiliesQuery } from '../../queries/lancamento/queries'
import { CircularProgress } from '@material-ui/core'
import { ModalClientSelect } from './components/ModalClientSelect'
import { NavigationLinks } from './components/NavigationLinks'
import '../../assets/css/always-visible.css'

export const SidebarV2 = ({ id, position, logo, routes }) => {
  const { tipoUsuario: userType } = useCoreContext()
  const { value: familyId } = useLocalStorage('familia-id')
  const { theme } = useTheme()
  const { family } = useFamilyQuery(familyId ? parseInt(familyId as unknown as string) : undefined)
  const { families, refetchGetFamilies } = useGetFamiliesQuery()
  const { familyImage } = useGetImageBYFamilyId({ familyId: familyId })
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [modalSelecaoCliente, setModalSelecaoCliente] = useState(false)
  const tipoUsuario = Number(userType)

  const toggleCollapse = () => setCollapseOpen((value) => !value)

  return (
    <>
      <ModalClientSelect isOpen={modalSelecaoCliente} onClose={() => setModalSelecaoCliente(false)} />
      <Navbar
        className={`navbar-vertical ${position} navbar-light`}
        expand="md"
        id={id}
        style={{
          overflowX: 'hidden',
          background:
            tipoUsuario <= 1
              ? theme.colors?.backgroundSidebarManager || theme.colors.secondary
              : theme.colors.primary
        }}
      >
        <S.Container>
          {/* Mobile toggle menu */}
          <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
            <span className="navbar-toggler-icon" />
          </button>

          {!!logo && (
            <S.Logo
              $width={
                theme?.sizes?.web?.x !== undefined && theme?.sizes?.web?.x !== ''
                  ? theme?.sizes?.web?.x
                  : '100%'
              }
            >
              <Logo />
            </S.Logo>
          )}

          {tipoUsuario !== 2 && <Fitas />}

          <div
            className="family-background"
            style={{
              position: 'relative',
              display: 'flex',
              width: '97.5%',
              height: 70,
              justifyContent: 'center',
              paddingLeft: 45,
              background:
                tipoUsuario === 1
                  ? theme.colors?.backgroundSidebarManagerDarker || theme.colors.secondaryDarker
                  : theme.colors.primaryDarker
            }}
          >
            <CircularProgress
              size={24}
              style={
                tipoUsuario <= 1 && families == null && familyId == null
                  ? {
                      float: 'left',
                      position: 'absolute',
                      left: '140px',
                      top: '20px'
                    }
                  : { display: 'none' }
              }
            />
            <div
              className="family-change"
              style={
                tipoUsuario <= 1
                  ? {
                      background:
                        theme.colors?.backgroundSidebarManagerDarker || theme.colors.secondaryDarker,
                      position: 'absolute',
                      right: 0,
                      zIndex: -1
                    }
                  : {
                      background: theme.colors.primaryDarker,
                      position: 'absolute',
                      right: 0,
                      zIndex: -1
                    }
              }
              onClick={() => {
                if (tipoUsuario <= 1 && (families != null || familyId != null)) setModalSelecaoCliente(true)
              }}
            >
              <NavLink
                style={
                  tipoUsuario <= 1
                    ? {
                        background:
                          theme.colors?.backgroundSidebarManagerDarker || theme.colors.secondaryDarker
                      }
                    : {
                        display: 'none',
                        background: theme.colors.primaryDarker
                      }
                }
              >
                <i className="fas fa-chevron-right" style={{ fontSize: 15, color: '#ffffff' }}></i>
              </NavLink>
              <NavLink
                style={
                  tipoUsuario <= 1
                    ? {
                        display: 'none',
                        background:
                          theme.colors?.backgroundSidebarManagerDarker || theme.colors.secondaryDarker
                      }
                    : { background: theme.colors.primaryDarker }
                }
              >
                <i className="fa fa-refresh" style={{ fontSize: '20px', color: '#ffffff' }}></i>
              </NavLink>
            </div>
            <div>
              <button
                className="navbar-toggler"
                type="button"
                id="atualizar-familias-sidebar"
                onClick={() => refetchGetFamilies()}
              >
                Atualizar Famílias
              </button>
            </div>

            <div
              id="logo-familia"
              style={{
                backgroundImage: `url(${familyImage || '/v1/static/media/familia.b63f190f.png'})`,
                position: 'absolute',
                left: 0
              }}
            ></div>

            <div className="family-name" style={{ fontSize: '.9rem', paddingLeft: 0 }}>
              <b>
                {tipoUsuario <= 1 && (!family || (!!family && !family.nome)) && families != null ? (
                  <label style={{ marginBottom: 0, marginLeft: 10 }}>Selecione um cliente</label>
                ) : (
                  family?.nome
                )}
              </b>
            </div>
          </div>

          {/* Navigation links */}
          <NavigationLinks collapseOpen={collapseOpen} toggleCollapse={toggleCollapse} routes={routes} />
        </S.Container>
      </Navbar>
    </>
  )
}
