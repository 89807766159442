import React, { useEffect, useState, useCallback } from 'react'
import Button from './Button'
import { PluggyConnect } from 'react-pluggy-connect'
import { usePluggyToken } from '../../queries/bankingIntegration/queries'
import { useCurrentUserId } from '../../hooks/useCurrentUserId'
import { noticeError } from '../../services/Monitoring'
import { useLoadingScreen } from '../../hooks/useLoadingScreen'
import { useCreateConnectionMutation } from '../../queries/bankingIntegration/mutations'
import { BankIntegrationDaysModal } from '../Modal/ChoicesModal'
import { useCurrentFamilyId } from '../../hooks/useCurrentFamilyId'
import { Item, ItemStatus, ExecutionErrorCode } from 'pluggy-js'
import { useUserType } from '../../hooks/useUserType'

const isSandbox = process.env['REACT_APP_PLUGGY_SANDBOX']?.toLowerCase() === 'true'

interface PluggyError {
  message: string
  data?: {
    item?: Item
  }
  name?: string
}

interface Props {
  onSuccess?: () => void
}

const isWrongCredentialsError = (error: PluggyError) =>
  // @ts-expect-error expected-error pluggy values
  error?.data?.item?.status === ItemStatus.LOGIN_ERROR &&
  // @ts-expect-error expected-error pluggy values
  error?.data?.item?.error?.code === ExecutionErrorCode.INVALID_CREDENTIALS

const isPluggyError = (error?: Error | null | PluggyError): error is PluggyError => {
  return (
    error !== null &&
    error !== undefined &&
    !!error?.message &&
    !!(error as PluggyError)?.data?.item?.id &&
    !!(error as PluggyError)?.data?.item?.status
  )
}

export default ({ onSuccess }: Props) => {
  const { userId } = useCurrentUserId()
  const { familyId } = useCurrentFamilyId()
  const userType = useUserType()

  const [daysRange, setDaysRange] = useState<number>()
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(true)
  const close = useCallback(() => {
    setIsOpen(false)
    setDaysRange(undefined)
  }, [])

  const { isLoadingToken, isErrorToken, token, tokenError, isRefetchingToken } = usePluggyToken(
    userId,
    undefined,
    {
      enabled: isOpen,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      refetchOnMount: 'always'
    }
  )

  const { createConnection, isCreatingConnection, isConnectionCreationError, connectionCreationError } =
    useCreateConnectionMutation()

  const { setIsLoading } = useLoadingScreen()
  const isLoading = isOpen && isLoadingToken && isCreatingConnection

  const handleErrors = useCallback(
    (error?: Error | null | PluggyError) => {
      if (isPluggyError(error)) {
        // TODO: remover esse any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const userId = (error.data?.item as any)?.clientUserId
        const id = error.data?.item?.id
        const status = error.data?.item?.status
        const code = error.data?.item?.error?.code
        if (isWrongCredentialsError(error)) {
          let message = 'Credenciais incorretas. Tente novamente com outras credenciais.'
          message += `\nuserId: ${userId}. ItemId: ${id}`
          const customError = new Error(message)
          noticeError(customError, { userId, error })
        } else {
          let message = `A conexão '${id}' do usuário '${userId}' não funcionou`
          message += `\nStatus ${status}. Código do erro: ${code}`
          const customError = new Error(message)
          noticeError(customError, { userId, error })
        }
      } else {
        noticeError(error, { userId })
      }
    },
    [userId]
  )
  if (isErrorToken) handleErrors(tokenError)
  if (isConnectionCreationError) handleErrors(connectionCreationError)

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading, setIsLoading])

  const shouldRenderModal = isOpen && token && !isRefetchingToken && daysRange !== undefined

  if (userType !== 'pessoa') return <></>

  return (
    <React.Fragment>
      <Button text="Conectar contas" onClick={open} />
      <BankIntegrationDaysModal
        onChoice={setDaysRange}
        onClose={close}
        open={isOpen && daysRange === undefined}
      />
      {shouldRenderModal && (
        <PluggyConnect
          connectToken={token || ''}
          onClose={close}
          includeSandbox={isSandbox}
          connectorTypes={['PERSONAL_BANK', 'BUSINESS_BANK', 'INVOICE', 'INVESTMENT']}
          onSuccess={async ({ item: { id: itemId } }) => {
            if (daysRange !== undefined) {
              await createConnection({ itemId, userId, daysRange, familyId })
              onSuccess?.()
            } else {
              const message = 'Missing days range after pluggy connetion'
              const error = new Error(message)
              noticeError(error, { userId })
              throw error
            }
          }}
          onError={(err) => handleErrors({ ...err, name: 'pluggy_err' })}
        />
      )}
    </React.Fragment>
  )
}
