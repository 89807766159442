import { useLocalStorage, useTheme } from '../../../hooks'
import { useLoginMutation } from '../../../queries/auth/querries'
import { Card, CardBody, FormGroup, Form, Label, Input, InputGroup, Row, Col } from 'reactstrap'
import Logo from '../../../theme/logo'
import { Link, useHistory } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import Button from '../../../components/Buttons'
import { useRequiredAuth } from '../../../hooks/useAuth'
import { useCoreContext } from '../../../hooks/contexts/coreProvider'
import { safeClearStorage } from '../../../components/AuthRoute/utils'
import RelationsThemeDomains from '../../../theme/relationsThemeDomains.json'
import { AlertModal } from '../../../components/Modal'

export const Login = () => {
  const history = useHistory()
  const { setUsuarioEmail, setTipoUsuario, loginRedirect, setUsuarioId, setEmpresaId } = useCoreContext()
  const { setJwt: setRequiredAuthJwt } = useRequiredAuth()
  const { loginAsync, isSuccessLogin, isErrorLogin, credentials, loginReset } = useLoginMutation()
  const { value: valueJwt, setValue: setValueJwt } = useLocalStorage('jwt')
  const { theme, changeTheme } = useTheme()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [form, setForm] = useState({
    email: '',
    senha: ''
  })

  const authenticate = useCallback(() => {
    const { email, senha } = form

    if (!email || !senha) return

    loginAsync({
      email: form.email,
      password: form.senha
    })
  }, [form, loginAsync])

  useEffect(() => {
    if (isSuccessLogin && credentials) {
      const { jwt, token } = credentials || {}
      setValueJwt(jwt)

      setRequiredAuthJwt(jwt)
      setUsuarioEmail(token.email)
      setTipoUsuario(token.type)

      if (token?.theme) changeTheme(token.theme)
      if (token?.id) setUsuarioId(String(token?.id))
      if (token?.empresaId) setEmpresaId(String(token?.empresaId))
    }
  }, [
    changeTheme,
    credentials,
    isSuccessLogin,
    setEmpresaId,
    setRequiredAuthJwt,
    setTipoUsuario,
    setUsuarioEmail,
    setUsuarioId,
    setValueJwt
  ])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isSuccessLogin && loginRedirect && valueJwt) {
      timer = setTimeout(() => history.push('/admin/home'), 6000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [history, isSuccessLogin, loginRedirect, valueJwt])

  useEffect(() => {
    if (isErrorLogin) {
      setIsSubmitting(false)
      safeClearStorage()
    }
  }, [isErrorLogin, setValueJwt])

  useEffect(() => {
    safeClearStorage()
    const currentDomain = window.location.href.split('/')[2]
    for (const themeDomain in RelationsThemeDomains)
      if (currentDomain === themeDomain) changeTheme(`theme-${RelationsThemeDomains[themeDomain]}`)
  }, [])

  return (
    <>
      <Col
        lg="6"
        md="12"
        style={{
          backgroundColor: theme.colors.backgroundLogin || theme.colors.primary,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          minHeight: 240
        }}
      >
        <a
          href="https://meuvista.com/"
          target="_blank"
          rel="noreferrer noopener"
          style={{
            textAlign: 'center',
            maxWidth:
              theme.login?.imageSize?.x !== undefined && theme.login?.imageSize?.x !== ''
                ? theme.login?.imageSize?.x
                : '480px'
          }}
        >
          <Logo
            // theme={theme}
            sizeW={
              theme.login?.imageSize?.x !== undefined && theme.login?.imageSize?.x !== ''
                ? theme.login?.imageSize?.x
                : '100%'
            }
          />
        </a>
        <div style={{ position: 'absolute', bottom: 0, width: '100%' }} className="pb-3 mt-3">
          <div className="copyright text-center text-muted">
            © 2020 Todos os direitos reservados
            <span className="font-weight-bold ml-1">Vista</span>
          </div>
        </div>
      </Col>
      <Col
        lg="6"
        md="12"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f7faff',
          padding: '30px 0px 30px 0px'
        }}
      >
        {/* {theme.isWhiteLabel || this.state.hideRegisterLink ? (
          ''
        ) : (
          <Row className="mt-3">
            <Link to="/auth/registrar" className="alert-link mb-3" style={{ color: '#9947F4' }}>
              Cadastro exclusivo para planejadores financeiros
            </Link>
          </Row>
        )} */}

        <Card
          className="bg-secondary shadow border-0"
          style={{ width: '90%', borderRadius: 15, maxWidth: 500 }}
        >
          <CardBody className="px-lg-5 py-lg-5" style={{ backgroundColor: '#FFF', borderRadius: 15 }}>
            <div className="text-center text-muted mb-4">
              <h2 style={{ fontWeight: 700 }}>{theme.login.cardTitleText}</h2>
            </div>

            <Form
              role="form"
              onSubmit={(event) => {
                event.preventDefault()
                authenticate()
              }}
            >
              <FormGroup className="mb-3">
                <Label
                  for="email"
                  style={{
                    marginLeft: 15,
                    fontSize: '0.9rem',
                    fontWeight: 600
                  }}
                >
                  E-mail
                </Label>
                <InputGroup>
                  <Input
                    type="email"
                    value={form.email}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        email: e.target.value.trim()
                      })
                    }
                    // onKeyPress={({ key }) => {
                    //   if (key === 'Enter') authenticate()
                    // }}
                    style={{
                      borderRadius: 25,
                      paddingLeft: 20,
                      border: 'none',
                      backgroundColor: '#f7faff'
                    }}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label
                  for="senha"
                  style={{
                    marginLeft: 15,
                    fontSize: '0.9rem',
                    fontWeight: 600
                  }}
                >
                  Senha
                </Label>
                <InputGroup>
                  <Input
                    type="password"
                    value={form.senha}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        senha: e.target.value.trim()
                      })
                    }
                    // onKeyPress={({ key }) => {
                    //   if (key === 'Enter') authenticate()
                    // }}
                    style={{
                      borderRadius: 25,
                      paddingLeft: 20,
                      border: 'none',
                      backgroundColor: '#f7faff'
                    }}
                  />
                </InputGroup>
                <Row>
                  <Col className="text-right mt-2">
                    <Link to="/auth/reset" style={{ fontSize: '0.9rem', fontWeight: 600 }}>
                      Esqueci minha senha
                    </Link>
                  </Col>
                </Row>
              </FormGroup>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Button
                  className="featured-button my-4"
                  color="primary"
                  disabled={isSubmitting}
                  // @ts-expect-error
                  style={{
                    background:
                      `linear-gradient(45deg, ${theme.colors.primary} 0%, ` +
                      `${theme.colors.secondary} 100%)`,
                    minWidth: 150,
                    textTransform: 'uppercase',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onClick={() => {
                    setIsSubmitting(true)
                    authenticate()
                  }}
                  text={isSubmitting ? 'Entrando...' : 'Entrar'}
                />
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>

      <AlertModal title="Falha no Login" open={isErrorLogin} onClose={loginReset}>
        Usuário ou senha inválidos.
      </AlertModal>
    </>
  )
}
