// @ts-nocheck TS2322
import React from 'react'
import { useCurrentFamilyId } from './hooks/useCurrentFamilyId'
import { CurrentFamilyProvider } from './hooks/contexts/currentFamilyContext'
import { LoadingScreenProvider } from './hooks/contexts/loadingScreenContext'
import { LoadingScreen } from './components/Loading'
import { Faturas } from './views/internas'
import SimuladorSeguro from './views/internas/SimuladorSeguro'
import lazyWithRetry from './lazyWithRetry'
import { Equity } from './views/internas/Equity'
import { Tutorials } from './views/internas/Tutorials'
import { Login } from './views/auth/Login/index'

const Index = lazyWithRetry(() => import('./views/Index'))
const Register = lazyWithRetry(() => import('./views/auth/Register'))
// const Login = lazyWithRetry(() => import('./views/auth/Login'))
const AlterarSenha = lazyWithRetry(() => import('./views/auth/TrocarSenha'))
const Reset = lazyWithRetry(() => import('./views/auth/Reset'))
const HabilitarUsuario = lazyWithRetry(() => import('./views/auth/HabilitarUsuario'))
const Bancos = lazyWithRetry(() => import('./views/internas/Bancos'))
const Orcamentos = lazyWithRetry(() => import('./views/internas/Orcamentos'))
const OrcamentoGeral = lazyWithRetry(() => import('./views/internas/Orcamento'))
const Categorias = lazyWithRetry(() => import('./views/internas/Categorias'))
const MeioPagamento = lazyWithRetry(() => import('./views/internas/MeioPagamento'))
const BucketList = lazyWithRetry(() => import('./views/internas/BucketList'))
const Lancamentos = lazyWithRetry(() => import('./views/internas/Lancamentos'))
const PlanosSonhos = lazyWithRetry(() => import('./views/internas/PlanosSonhos'))
const GestaoPlanos = lazyWithRetry(() => import('./views/internas/GestaoPlanos'))
const Aposentadoria = lazyWithRetry(() => import('./views/internas/Aposentadoria'))
const Integracoes = lazyWithRetry(() => import('./views/internas/Integracoes'))
const Familias = lazyWithRetry(() => import('./views/internas/Familias'))
const Pessoas = lazyWithRetry(() => import('./views/internas/Pessoas'))
const Planejadores = lazyWithRetry(() => import('./views/internas/Planejadores'))
const ClientesCancelados = lazyWithRetry(() => import('./views/internas/ClientesCancelados'))
const Equilibrio = lazyWithRetry(() => import('./views/internas/equilibrio/Equilibrio'))
const Wizard = lazyWithRetry(() => import('./views/internas/wizard/Wizard'))
const Dividas = lazyWithRetry(() => import('./views/internas/Dividas'))
const Patrimonios = lazyWithRetry(() => import('./views/internas/Patrimonios'))
const DestinacaoPatrimonio = lazyWithRetry(() => import('./views/internas/DestinacaoPatrimonio'))
const DestinacaoInvestimento = lazyWithRetry(() => import('./views/internas/DestinacaoInvestimentos'))
const MateriaisVista = lazyWithRetry(() => import('./views/internas/MateriaisVista'))
const Eventos = lazyWithRetry(() => import('./views/internas/Eventos'))
const Leads = lazyWithRetry(() => import('./views/internas/IndexLeads'))
const TermosPrivacidade = lazyWithRetry(() => import('./views/internas/TermosPrivacidade'))
const TermosUso = lazyWithRetry(() => import('./views/internas/TermosUso'))
const Whitelabel = lazyWithRetry(() => import('./views/internas/Whitelabel'))
const Simuladores = lazyWithRetry(() => import('./views/internas/Simuladores'))

// TODO: Improve these wrappers for easier usage
const WithLoadingScreen = (Children: () => JSX.Element) => {
  return (
    <LoadingScreenProvider>
      <LoadingScreen />
      <Children />
    </LoadingScreenProvider>
  )
}

const WithCurrentFamily = (Children: () => JSX.Element) => {
  const { familyId } = useCurrentFamilyId()
  return (
    <CurrentFamilyProvider familyId={familyId}>
      <Children />
    </CurrentFamilyProvider>
  )
}

interface Route {
  betaTag?: boolean
  betaUserOnly?: boolean
  component?: // eslint-disable-next-line @typescript-eslint/ban-types
  | React.LazyExoticComponent<React.ComponentType<{}> | React.FC<object>>
    | JSX.Element
    | ((props?: unknown) => JSX.Element)
  configurationMenu?: boolean
  header?: string
  hideOnSidebar?: boolean
  icon: string
  layout?: '/admin' | '/auth'
  loggedOnly?: boolean
  name?: string
  onlyManager?: boolean
  path?: string
  perfis?: number[]
  verificacaoFamiliaSelecionada?: boolean
  redirect?: () => { url: string }
  inactive?: boolean
}

const getRoutePath = (route?: Route) => (route?.layout || '') + (route?.path || '')
const getRoute = (name: string) => routes.find((r) => r.name === name)
export const getRouteByPath = ({ path, userType }: { path: string; userType?: number }) =>
  routes.find((route) => {
    if (typeof userType === 'number') {
      if (route.path === path && route?.perfis?.includes(userType)) {
        return true
      }
    } else {
      if (route.path === path) {
        return true
      }
    }
  })
const getRouteNameByPath = (path: string) => routes.find((route) => route.path === path)?.name

const loginPath = '/login'
const routes: Route[] = [
  {
    header: 'VISTA',
    icon: 'fas fa-tv',
    perfis: [0, 1, 2]
  },
  {
    path: '/home',
    name: 'Home',
    icon: 'fas fa-angle-right',
    component: Index,
    layout: '/admin',
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/equilibrio-financeiro',
    name: 'Equilíbrio Financeiro',
    icon: 'fas fa-angle-right',
    component: Equilibrio,
    layout: '/admin',
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    header: 'OBJETIVOS',
    icon: 'fas fa-star',
    perfis: [0, 1, 2]
  },
  {
    path: '/planos-sonhos',
    name: 'Planos & Sonhos',
    icon: 'fas fa-angle-right',
    component: PlanosSonhos,
    layout: '/admin',
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/gestao-planos-sonhos',
    name: 'Gestão de Planos',
    icon: 'fas fa-angle-right',
    component: GestaoPlanos,
    layout: '/admin',
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/independencia-financeira',
    name: 'Independência Financeira',
    icon: 'fas fa-angle-right',
    component: Aposentadoria,
    layout: '/admin',
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    header: 'ORÇAMENTO',
    icon: 'fas fa-coins',
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/orcamento',
    name: 'Gestão de Orçamento',
    icon: 'fas fa-angle-right',
    component: OrcamentoGeral,
    layout: '/admin',
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/dividas',
    name: 'Dívidas',
    icon: 'fas fa-angle-right',
    component: Dividas,
    layout: '/admin',
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/extrato',
    name: 'Extrato',
    icon: 'fas fa-angle-right',
    component: Lancamentos,
    layout: '/admin',
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/integracoes',
    name: 'Integrações',
    icon: 'fas fa-angle-right',
    // @ts-expect-error expected-error: lazyload import
    component: () => WithLoadingScreen(() => WithCurrentFamily(Integracoes)),
    layout: '/admin',
    perfis: [0, 1, 2],
    betaTag: true,
    loggedOnly: true
  },
  {
    header: 'FINANCEIRO',
    icon: 'fas fa-chart-line',
    perfis: [0, 1, 2]
  },
  // { // DELETAR APÓS MIGRAÇÃO PARA PATRIMÔNIO V2
  //   path: '/investimentos',
  //   name: 'Patrimônio - Integração',
  //   icon: 'fas fa-angle-right',
  //   component: Investments,
  //   layout: '/admin',
  //   hideOnSidebar: false,
  //   perfis: [0, 1, 2],
  //   betaTag: true,
  //   loggedOnly: true
  // },
  {
    path: '/patrimonio-integracao',
    name: 'Patrimônio - Integração',
    icon: 'fas fa-angle-right',
    component: Equity,
    layout: '/admin',
    hideOnSidebar: false,
    perfis: [0, 1, 2],
    betaTag: true
  },
  {
    path: '/patrimonio/:open',
    name: 'Patrimônio',
    icon: 'fas fa-angle-right',
    component: Patrimonios,
    layout: '/admin',
    hideOnSidebar: true,
    loggedOnly: true
  },
  {
    path: '/patrimonio',
    name: 'Patrimônio',
    icon: 'fas fa-angle-right',
    component: Patrimonios,
    layout: '/admin',
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/destinacao-patrimonio',
    name: 'Destinação de Patrimônio',
    icon: 'fas fa-angle-right',
    component: DestinacaoPatrimonio,
    layout: '/admin',
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/destinacao-investimentos',
    name: 'Destinação de Investimentos',
    icon: 'fas fa-angle-right',
    component: DestinacaoInvestimento,
    layout: '/admin',
    perfis: [0, 1, 2],
    loggedOnly: true,
    inactive: true
  },
  {
    path: '/whitelabel',
    name: 'Whitelabel',
    icon: 'fas fa-angle-right',
    component: Whitelabel,
    layout: '/admin',
    perfis: [1],
    loggedOnly: true
  },
  // {
  //   path: '/seguros',
  //   name: 'Gestão de Risco',
  //   icon: 'fas fa-angle-right',
  //   component: Seguros,
  //   layout: '/admin',
  //   perfis: [1],
  //   loggedOnly: true
  // },
  {
    header: 'NOVO VISTA',
    icon: 'fas fa-star',
    perfis: [0, 1, 2]
  },
  {
    path: '/resumo-vista',
    name: 'Resumo Vista',
    icon: 'fas fa-angle-right',
    redirect: () => {
      let url = `${process.env.REACT_APP_PUBLIC_URL_V2}/resumo-vista`

      if (!window.location.host.includes('localhost')) {
        url = `https://${window.location.host}/v2/resumo-vista`
      }

      // console.log('Redirecionando p/ v2 em', url)
      // window.location.href = url
      return {
        url
      }
    },
    layout: '/admin',
    perfis: [0, 1, 2],
    betaTag: true,
    loggedOnly: true
  },
  {
    path: '/bucketlist',
    name: 'Lista de Sonhos',
    icon: 'ni ni-bullet-list-67 text-red',
    component: BucketList,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0],
    loggedOnly: true
  },
  {
    path: '/planejadores',
    name: 'Planejadores',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Planejadores,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0],
    loggedOnly: true
  },
  {
    path: '/planejadores',
    name: 'Planejador',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Planejadores,
    layout: '/admin',
    configurationMenu: true,
    perfis: [1],
    loggedOnly: true
  },
  {
    path: '/faturas',
    name: 'Faturas',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Faturas,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0, 1],
    loggedOnly: true
  },
  {
    path: '/clientes/:open',
    name: 'Clientes - Novo',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Familias,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0, 1],
    hideOnSidebar: true,
    loggedOnly: true
  },
  {
    path: '/clientes',
    name: 'Clientes',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Familias,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0, 1],
    loggedOnly: true
  },
  {
    path: '/usuarios/:open',
    name: 'Usuários - Novo',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Pessoas,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0, 1, 2],
    hideOnSidebar: true,
    loggedOnly: true
  },
  {
    path: '/cancelados',
    name: 'Clientes Cancelados',
    icon: 'ni ni-bullet-list-67 text-red',
    component: ClientesCancelados,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0],
    loggedOnly: true
  },
  {
    path: '/usuarios',
    name: 'Usuários',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Pessoas,
    layout: '/admin',
    configurationMenu: true,
    verificacaoFamiliaSelecionada: true,
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/orcamentos',
    name: 'Orçamentos',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Orcamentos,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0],
    loggedOnly: true
  },
  {
    path: '/categorias',
    name: 'Categorias',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Categorias,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0],
    loggedOnly: true
  },
  {
    path: '/bancos',
    name: 'Bancos',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Bancos,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0],
    loggedOnly: true
  },
  {
    path: '/meios-pagamento',
    name: 'Meios de Pagamento',
    icon: 'ni ni-bullet-list-67 text-red',
    component: MeioPagamento,
    layout: '/admin',
    hideOnSidebar: false,
    configurationMenu: true,
    verificacaoFamiliaSelecionada: true,
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/wizard',
    name: 'Configuração Inicial',
    icon: 'ni ni-tv-2 text-primary',
    component: Wizard,
    layout: '/admin',
    configurationMenu: true,
    verificacaoFamiliaSelecionada: true,
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  // {
  //   path: '/tutoriais',
  //   name: 'Tutoriais',
  //   icon: 'ni ni-button-play text-primary',
  //   component: Tutoriais,
  //   layout: '/admin',
  //   configurationMenu: true,
  //   verificacaoFamiliaSelecionada: true,
  //   perfis: [0, 1],
  //   loggedOnly: true
  // },
  {
    path: '/tutoriais',
    name: 'Tutoriais',
    icon: 'ni ni-button-play text-primary',
    // @ts-expect-error component: () => JSX.Element
    component: Tutorials,
    layout: '/admin',
    configurationMenu: true,
    verificacaoFamiliaSelecionada: true,
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/materiais',
    name: 'Materiais Vista',
    icon: 'ni ni-collection text-primary',
    component: MateriaisVista,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0],
    loggedOnly: true
  },
  {
    path: '/eventos',
    name: 'Eventos Vista',
    icon: 'ni ni-collection text-primary',
    component: Eventos,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0],
    loggedOnly: true
  },
  {
    path: '/indexleads',
    name: 'Leads Vista',
    icon: 'ni ni-collection text-primary',
    component: Leads,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0],
    loggedOnly: true
  },
  {
    path: '/termosprivacidade',
    name: 'Termos Privacidade Vista',
    icon: 'ni ni-collection text-primary',
    component: TermosPrivacidade,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/termosuso',
    name: 'Termos Uso Vista',
    icon: 'ni ni-collection text-primary',
    component: TermosUso,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/alterar-senha',
    name: 'Alterar Senha',
    icon: 'ni ni-key-25 text-info',
    component: AlterarSenha,
    layout: '/admin',
    configurationMenu: true,
    perfis: [0, 1, 2],
    loggedOnly: true
  },
  {
    path: '/registrar',
    name: 'Cadastro',
    icon: 'ni ni-key-25 text-info',
    component: Register,
    layout: '/auth',
    hideOnSidebar: true
  },
  {
    path: loginPath,
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    hideOnSidebar: true
  },
  {
    path: `${loginPath}/to/:redirectUrl`,
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    hideOnSidebar: true
  },
  {
    path: '/reset/:token',
    name: 'Reset Senha',
    icon: 'ni ni-key-25 text-info',
    component: Reset,
    layout: '/auth',
    hideOnSidebar: true
  },
  {
    path: '/reset',
    name: 'Reset Senha',
    icon: 'ni ni-key-25 text-info',
    component: Reset,
    layout: '/auth',
    hideOnSidebar: true
  },
  {
    path: '/habilitar-usuario/:id',
    name: 'Habilitar Usuário',
    icon: 'ni ni-key-25 text-info',
    component: HabilitarUsuario,
    layout: '/auth',
    hideOnSidebar: true
  },
  {
    path: '/simuladores',
    name: 'Vista Simuladores',
    icon: 'ni ni-key-25 text-info',
    component: Simuladores,
    layout: '/admin',
    hideOnSidebar: true,
    loggedOnly: true
  },
  {
    path: '/simulador/:simulador',
    name: 'Vista Simulador',
    icon: 'ni ni-key-25 text-info',
    // @ts-expect-error expected-error
    component: SimuladorSeguro,
    layout: '/admin',
    hideOnSidebar: true,
    loggedOnly: true
  },
  {
    path: '/whitelabel',
    name: 'Whitelabel',
    icon: 'ni ni-key-25 text-info',
    component: Whitelabel,
    layout: '/admin',
    hideOnSidebar: true,
    loggedOnly: true
  }
  // {
  //   path: '/seguros',
  //   name: 'Vista Seguros',
  //   icon: 'ni ni-key-25 text-info',
  //   component: Seguros,
  //   layout: '/admin',
  //   hideOnSidebar: true,
  //   loggedOnly: true
  // }
]

const getLoginRoute = () => {
  const route = getRoute('Login')
  if (!route) throw new Error('Login route not found')
  return route
}

// const getAdminRouteInfo = (prop = 'path') =>
//   routes
//     .map((item) => {
//       if (item.layout === '/admin') return item[prop]
//     })
//     ?.filter((a) => a)

export const ROUTE_PATHS = routes.map((item) => item.path).filter((item) => !!item)

console.log({ ROUTE_PATHS })

export type RoutePathsAdminAlternatives =
  | '/alterar-senha'
  | '/bancos'
  | '/bucketlist'
  | '/cancelados'
  | '/categorias'
  | '/clientes'
  | '/clientes/:open'
  | '/destinacao-investimentos'
  | '/destinacao-patrimonio'
  | '/dividas'
  | '/equilibrio-financeiro'
  | '/eventos'
  | '/extrato'
  | '/faturas'
  | '/gestao-planos-sonhos'
  | '/home'
  | '/independencia-financeira'
  | '/indexleads'
  | '/integracoes'
  | '/investimentos'
  | 'integracoes'
  | '/materiais'
  | '/meios-pagamento'
  | '/orcamento'
  | '/orcamentos'
  | '/patrimonio'
  | '/patrimonio-integracao'
  | '/patrimonio/:open'
  | '/planejadores'
  | '/planejadores'
  | '/planos-sonhos'
  | '/resumo-vista'
  | '/whitelabel'
  | '/simulador/:simulador'
  | '/simuladores'
  | '/termosprivacidade'
  | '/termosuso'
  | '/tutoriais'
  | '/usuarios'
  | '/usuarios/:open'
  | '/wizard'

export default routes
export { getLoginRoute, getRoute, getRouteNameByPath, getRoutePath, loginPath }
