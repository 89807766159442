import React, { useState } from 'react'
import useKonami from 'use-konami'
import { ThemeSelect } from '../../Selects'
import Modal from '../Modal'

export const ThemeModal = () => {
  const [isOpen, setOpen] = useState(false)
  useKonami({
    onUnlock: () => setOpen(true),
    sequence: ['Control', 'Control', 'Control', ' ', 'ArrowUp', 'ArrowUp', 'ArrowUp']
  })

  if (!isOpen) return <></>

  return (
    <Modal open={isOpen} closeable={true} onClose={() => setOpen(false)}>
      <ThemeSelect />
    </Modal>
  )
}
