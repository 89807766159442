import { useQuery } from 'react-query'
import { getFamilyById, getImageBYFamilyIdApi } from '../../domain/family/api/getFamilyById'
import { CancellationReason, Family } from '../../domain/family/Family'
import { useCurrentFamilyId } from '../../hooks/useCurrentFamilyId'
import { noticeError } from '../../services/Monitoring'
import { QueryOptions } from '../queryOptions'
import { getCancelationReasons } from '../../domain/family/api/getCancelationReasons'

export const cacheBaseKey = 'family'
export const cancellationReasonKey = 'familyCancelationReason'

export const useCurrentFamilyQuery = (options?: QueryOptions<Family | undefined>) => {
  const { familyId } = useCurrentFamilyId()
  return useFamilyQuery(familyId, options)
}

export const useFamilyQuery = (familyId?: number, options?: QueryOptions<Family | undefined>) => {
  const {
    isLoading: isLoadingFamily,
    isError: isErrorFamily,
    data: family,
    error: familyError
  } = useQuery<unknown, Error, Family | undefined>(
    [cacheBaseKey, familyId],
    () => {
      if (!familyId) {
        const error = new Error('Missing familyId in useCurrentFamilyQuery')
        noticeError(error)
        throw error
      }
      return getFamilyById({ familyId })
    },
    {
      enabled: !!familyId,
      ...options
    }
  )

  return {
    isLoadingFamily,
    isErrorFamily,
    family,
    familyError
  }
}

export const useGetImageBYFamilyId = ({ familyId }) => {
  const {
    data,
    error: familyImageError,
    isLoading: isLoadingFamilyImage,
    isError: isErrorFamilyImage,
    refetch: familyImageRefetch,
    isFetched: isFetchedFamilyImage
  } = useQuery(
    [cacheBaseKey, familyId],
    () => {
      if (!familyId) {
        const error = new Error('Missing familyId in useGetImageBYFamilyId')
        noticeError(error)
        throw error
      }

      return getImageBYFamilyIdApi({ familyId })
    },
    {
      enabled: !!familyId,
      retry: 2,
      retryDelay: 3000
    }
  )

  console.log('useGetImageBYFamilyId ========== >', { familyId })

  const { data: familyImage } = data || {}

  return {
    familyImage: familyImage?.singleResult,
    familyImageError,
    isLoadingFamilyImage,
    isErrorFamilyImage,
    isFetchedFamilyImage,
    familyImageRefetch
  }
}

export const useCancellationReasonsOptions = () => {
  const {
    isLoading: isLoadingCancellationReason,
    isError: isErrorCancellationReason,
    data: cancellationReasons,
    error: cancellationError
  } = useQuery<unknown, Error, CancellationReason[] | undefined>([cancellationReasonKey], () =>
    getCancelationReasons()
  )

  return {
    isLoadingCancellationReason,
    isErrorCancellationReason,
    cancellationReasons,
    optionsReasons:
      cancellationReasons
        ?.map((item) => ({ text: item.descricao, value: item.id }))
        ?.sort((a, b) => a.text.localeCompare(b.text)) ?? [],
    cancellationError
  }
}
