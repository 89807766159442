import React from 'react'
import { useAuth } from '../../hooks/contexts/authContext'
import { Route, RouteProps } from 'react-router-dom'
import { getLoginPath, RedirectToHome, RedirectToLogin } from './utils'
import { getRouteByPath } from '../../routes'

export const AuthRoute = (routeProps: RouteProps) => {
  const auth = useAuth()
  const { token } = auth || {}
  const familyId = token?.familiaId
  const pathname = routeProps.location?.pathname
  const loginPath = getLoginPath()
  const replacedData = pathname?.replace('/admin', '') ?? ''
  const pathData = getRouteByPath({ path: replacedData, userType: Number(token?.type) })

  if (!token && pathData?.loggedOnly) {
    if (pathname === loginPath) return <Route {...routeProps} />
    return <RedirectToLogin />
  }

  if (token?.type && pathData?.loggedOnly && !pathData?.perfis?.includes(Number(token?.type))) {
    console.log('redirecionando p/ home usuario planejador')
    return <RedirectToHome />
  }

  if (
    token?.type &&
    pathData?.loggedOnly &&
    [0, 1]?.includes(Number(token?.type)) &&
    !!familyId &&
    !pathData?.perfis?.includes(2)
  ) {
    console.log('redirecionando p/ home admin ou planejador')
    return <RedirectToHome />
  }

  return <Route {...routeProps} />
}
