import React, { useCallback, useEffect, useState, useMemo } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import CurrencyInput from 'react-currency-input'
import { Row, Col } from 'reactstrap'
import { INVESTMENT_NAME_CODE, OPERACAO_NAME_CODE } from './types'
import { InputQuantity } from '../../../../Inputs/InputQuantidade'
import { useSaveTransactionsActions } from '../SaveDataTransaction/useSaveTransactionAction'
import { useSelectComboContext } from '../../../../../hooks/contexts/selectComboContext'
import { FieldSelectOrcamento } from '../../../../Selects/FieldSelectOrcamento'
import { TipoOrcamento } from '../../../../../domain/orcamento/Orcamento'
import { FieldSelectCategoria } from '../../../../Selects/FieldSelectCategoria'
import { FieldSelectPaymentMethods } from '../../../../Selects/FieldSelectPaymentMethods'
import { useTransactionContext } from '../../../../../hooks/contexts/transactionContext'
import { TIPO_ORCAMENTO_NAME_CODE, Transaction } from '../SaveDataTransaction/types'
import { FieldSelectDate } from '../../../../Selects/FieldSelectDate'
import { FieldInputAmount } from '../../../../Inputs/InputAmount'
import moment from 'moment'
import { getTransactionType, TRANSACTION_NAME_CODE } from '../../../../../domain/lancamento/lancamento'

export const FormReceita = ({ parentThis }) => {
  const { SaveButton } = useSaveTransactionsActions()
  const { MEIOS_PAGAMENTOS_OPTIONS } = useSelectComboContext()
  const { transaction, setTransaction } = useTransactionContext()
  const [item, setStateItem] = useState<Transaction | undefined>(transaction)
  const { state } = parentThis || {}
  const [paymentMethod, setPaymentMethod] = useState(item?.meioPagamento)
  const isEditing = !!item?.id
  const { isParceled, isRepeated } = getTransactionType({
    transactionType: item?.tipoDeParcela ?? TRANSACTION_NAME_CODE.unico
  })

  const setItem = (data, callback?: () => void) => {
    setStateItem({
      ...item,
      ...data
    })
    if (callback) {
      setTimeout(() => {
        callback?.()
      }, 100)
    }
  }

  const initialData = useMemo(() => {
    let temp = item
    if (item && !item?.repeatedParcels) {
      temp = {
        ...item,
        repeatedParcels: item?.parcelas
      }
    }

    return temp
  }, [item])

  const findMeioPagamento = useCallback(
    (findMeioPagamentoPadrao = false) => {
      if (item && MEIOS_PAGAMENTOS_OPTIONS) {
        if (item?.meioPagamento?.nome && !findMeioPagamentoPadrao) {
          const meioPagamento = MEIOS_PAGAMENTOS_OPTIONS?.find((op) => op.label === item?.meioPagamento.nome)
          // @ts-expect-error mismatch
          setPaymentMethod(meioPagamento?.value)
        } else if (item?.categoria?.meioPagamentoPadrao?.nome) {
          const meioPagamentoPadrao = MEIOS_PAGAMENTOS_OPTIONS.find(
            (op) => op.label === item?.categoria.meioPagamentoPadrao.nome
          )
          // @ts-expect-error mismatch
          setPaymentMethod(meioPagamentoPadrao?.value)
        }
      }
    },
    [MEIOS_PAGAMENTOS_OPTIONS, item]
  )

  const [validForm, setValidForm] = useState(false)
  const validateForm = useCallback(() => {
    const { categoria, meioPagamentoId, valor } = item || {}

    if (categoria === null || meioPagamentoId === null) {
      setValidForm(false)
      return
    }

    if (!isEditing && !valor) {
      setValidForm(false)
      return
    }

    setValidForm(true)
  }, [isEditing, item])

  useEffect(() => {
    findMeioPagamento()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findMeioPagamento, item?.meioPagamento])

  useEffect(() => {
    setItem({
      meioPagamento: paymentMethod
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod])

  useEffect(() => {
    validateForm()
  }, [item, validateForm])

  useEffect(() => {
    const _transaction = transaction || {}
    setItem({
      ..._transaction,
      data: transaction?.data ?? moment(new Date(), 'DD-MM-YYYY').toDate(),
      tipoOrcamento: TIPO_ORCAMENTO_NAME_CODE.Receita
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (validForm) {
      setTransaction(item)
    }
  }, [item, setTransaction, validForm])

  return (
    <Row id="formReceita">
      <FieldSelectOrcamento
        type={TipoOrcamento.Receita}
        selectedId={item?.orcamentoId}
        // @ts-expect-error expected-error
        defaultValue={item?.id && item?.orcamentoId ? item?.orcamentoId : undefined}
        // @ts-expect-error expected-error
        onSelect={({ value }) => {
          // parentThis.handleChangeOrcamento({ value })
          setItem({
            orcamento: value,
            orcamentoId: value?.id
          })
        }}
      />

      {/* <Col lg="6" xl="6" className="lancamento receita">
        <div className="lancamento receita">
          <label>Categoria *</label>
          {parentThis.montarDropDown(
            state.categoriasDeReceitasCombo,
            'lancamentoCategoria',
            parentThis.handleChangeCategoria,
            item?.categoria
          )}
        </div>
      </Col> */}
      <FieldSelectCategoria
        lancamentoId={item?.orcamento?.id ?? item?.orcamentoId}
        defaultValue={
          item?.categoria?.id ?? typeof item?.categoriaId === 'number' ? Number(item?.categoriaId) : undefined
        }
        onSelect={({ categoria }) => {
          const meioPagamento = categoria.meioPagamentoPadrao ?? item?.meioPagamento ?? null

          let newItem = {
            categoria: categoria,
            categoriaId: categoria?.id
          }

          if (categoria?.meioPagamentoPadrao) {
            newItem = {
              ...newItem,
              // @ts-expect-error
              meioPagamento: meioPagamento,
              meioPagamentoId: meioPagamento?.id
            }
          }

          setItem(newItem, () => findMeioPagamento(true))
        }}
      />

      {item?.patrimonio?.tipoInvestimento === INVESTMENT_NAME_CODE.RendaFixaPos &&
        item?.operacao !== OPERACAO_NAME_CODE.VENDA && (
          <Col lg="6" xl="6">
            <div>
              <label>Taxa *</label>
              <CurrencyInput
                id="lancamentoTaxaCustomizada"
                value={
                  item?.taxaCustomizadaFormatada != null
                    ? item?.taxaCustomizadaFormatada
                    : item?.taxaCustomizada
                }
                thousandSeparator={'.'}
                decimalSeparator={','}
                precision="2"
                suffix=" %"
                onChangeEvent={(_, formattedValue, value) => {
                  setItem({
                    taxaCustomizada: value,
                    taxaCustomizadaFormatada: formattedValue
                  })
                  // parentThis.setState({
                  //   item: {
                  //     ...item,
                  //     taxaCustomizada: value,
                  //     taxaCustomizadaFormatada: formattedValue
                  //   }
                  // })
                }}
              />
            </div>
          </Col>
        )}

      {/* <Col
        lg="6"
        xl="6"
        style={
          item?.patrimonio != null && item?.patrimonio.tipoInvestimento === 6 && item?.operacao !== 1
            ? {}
            : { display: 'none' }
        }
      >
        <div>
          <label>Taxa Anual *</label>
          <CurrencyInput
            id="lancamentoTaxaAnual"
            value={item?.taxaAnualFormatada != null ? item?.taxaAnualFormatada : item?.taxaAnual}
            thousandSeparator={'.'}
            decimalSeparator={','}
            precision="2"
            suffix=" %"
            onChangeEvent={(_event, formattedValue, value) => {
              setItem({
                taxaAnual: value,
                taxaAnualFormatada: formattedValue
              })
              // parentThis.setState({
              //   item: {
              //     ...item,
              //     taxaAnual: value,
              //     taxaAnualFormatada: formattedValue
              //   }
              // })
            }}
          />
        </div>
      </Col> */}

      {/* <Col
        lg="6"
        xl="6"
        style={
          item?.patrimonio != null &&
          (item?.patrimonio.tipoInvestimento === 3 || item?.patrimonio.tipoInvestimento === 6) &&
          item?.operacao !== 1
            ? {}
            : { display: 'none' }
        }
      >
        <div>
          <label>Data de Vencimento *</label>
          {parentThis.gerarDatePicker(
            'dataVencimento',
            (_, date) => {
              setItem({
                dataVencimento: date
              })
              // parentThis.setState({
              //   item: {
              //     ...item,
              //     dataVencimento: date
              //   }
              // })
            },
            parentThis.moment()
          )}
        </div>
      </Col> */}

      {/* <Col
        lg="6"
        xl="6"
        style={
          item?.patrimonio != null &&
          (item?.patrimonio.tipoInvestimento === 3 || item?.patrimonio.tipoInvestimento === 6) &&
          item?.operacao === 1
            ? {}
            : { display: 'none' }
        }
      >
        <div>
          <label>Ativo *</label>
          {parentThis.montarDropDown(
            state.patrimonioAtivosCombo,
            'lancamentoPatrimonioAtivo',
            ({ value }) => {
              setItem({
                lancamentoPatrimonioAtivo: value
              })
              // parentThis.setState({ item: { ...item, lancamentoPatrimonioAtivo: value } })
            },
            item?.lancamentoPatrimonioAtivo
          )}
        </div>
      </Col> */}

      <FieldSelectPaymentMethods
        defaultValue={
          item?.meioPagamentoId ??
          item?.financialInstitutionCode ??
          item?.meioPagamento?.id ??
          paymentMethod?.id
        }
        onSelect={({ value }) => {
          setItem({
            meioPagamento: value,
            meioPagamentoId: value?.id
          })
          // parentThis.setState({
          //   item: {
          //     ...item,
          //     meioPagamento: value,
          //     meioPagamentoId: value?.id
          //   }
          // })
        }}
      />

      {item?.patrimonio?.tipoPatrimonio &&
        INVESTMENT_NAME_CODE[item?.patrimonio?.tipoPatrimonio] &&
        item?.operacao !== OPERACAO_NAME_CODE.VENDA && (
          <Col lg="6" xl="6">
            <div>
              <label>Origem do Recurso *</label>
              {parentThis.montarDropDown(
                state.meiosPagamentoCombo,
                'lancamentoMeioPagamento',
                ({ value }) => {
                  setItem({
                    meioPagamentoNome: value
                  })
                  // parentThis.setState({
                  //   item: {
                  //     ...item,
                  //     meioPagamentoNome: value
                  //   }
                  // })
                },
                item?.meioPagamento
              )}
            </div>
          </Col>
        )}

      <FieldInputAmount
        partialData={{
          id: item?.id,
          valor: item?.valor,
          parcelas: item?.parcelas,
          valorParcela: item?.valorParcela,
          tipoDeParcela: item?.tipoDeParcela
        }}
        onChange={(data) => {
          setItem({ ...data })
          return
        }}
      />
      {/* <Col lg="6" xl="6" className="lancamento receita">
        <div>
          <label className="lancamento receita">
            Valor{!isEditing && item?.tipoDeParcela === 0 && ' total'}
            {isEditing && isParceled && ' da parcela'} *
          </label>

          <CurrencyInput
            id="lancamentoValor"
            style={{ height: 38, paddingLeft: 25 }}
            value={isEditing && (isParceled || isRepeated) ? item?.valorParcela : item?.valor}
            // defaultValue={item?.id && getInputAmount()}
            thousandSeparator={'.'}
            decimalSeparator={','}
            precision="2" //prefix={'R$ '}
            onChangeEvent={(_, formattedValue, value) => {
              if (isEditing) {
                setItem({
                  valor: value,
                  editRepeated: isRepeated,
                  editInstallments: isParceled,
                  valorParcela: isParceled || isRepeated ? value : null,
                  parcelado: isParceled || isRepeated,
                  parcelas: isParceled || isRepeated ? item?.parcelas : null,
                  valorFormatado: formattedValue
                })
                return
              }

              setItem({
                valor: value,
                valorFormatado: formattedValue
              })
            }}
          />
        </div>
      </Col> */}

      <FieldSelectDate
        id="lancamentoData"
        disabled={isEditing && (isParceled || isRepeated)}
        onChange={(date: Date) => {
          setItem({
            data: date
          })
        }}
        selectedValue={item?.data}
      />

      <Col lg="6" xl="6" className="lancamento receita">
        <div>
          <InputQuantity
            onRadioChange={(value) => {
              setItem({
                tipoDeParcela: value
              })
            }}
            onInputChange={(event) => setItem({ parcelas: event.target.value })}
            parcelas={item?.parcelas}
            tipoDeParcela={item?.tipoDeParcela}
            isEditing={isEditing}
            // @ts-expect-error initialData
            initialData={initialData}
          />
        </div>
      </Col>

      {item?.patrimonio?.tipoInvestimento &&
        [1, 2, 3, 4, 5, 6, 8, 9].includes(item?.patrimonio?.tipoInvestimento) &&
        item?.operacao === OPERACAO_NAME_CODE.VENDA && (
          <Col lg="6" xl="6">
            <div>
              <label>Imposto de Renda *</label>
              <CurrencyInput
                id="lancamentoImpostoRenda"
                style={{ paddingLeft: 25 }}
                value={item?.impostoRendaFormatado != null ? item?.impostoRendaFormatado : item?.impostoRenda}
                thousandSeparator={'.'}
                decimalSeparator={','}
                precision="2"
                onChangeEvent={(_, formattedValue, value) => {
                  setItem({
                    impostoRenda: value,
                    impostoRendaFormatado: formattedValue
                  })
                  // parentThis.setState({
                  //   item: {
                  //     ...item,
                  //     impostoRenda: value,
                  //     impostoRendaFormatado: formattedValue
                  //   }
                  // })
                }}
              />
            </div>
          </Col>
        )}

      {item?.patrimonio?.tipoInvestimento &&
        [1, 2, 3, 4, 5, 6, 8, 9].includes(item?.patrimonio?.tipoInvestimento) && (
          <Col lg="6" xl="6">
            <div>
              <label>IOF *</label>
              <CurrencyInput
                id="lancamentoIOF"
                style={{ paddingLeft: 25 }}
                value={item?.iofFormatado != null ? item?.iofFormatado : item?.iof}
                thousandSeparator={'.'}
                decimalSeparator={','}
                precision="2"
                onChangeEvent={(_, formattedValue, value) => {
                  setItem({
                    iof: value,
                    iofFormatado: formattedValue
                  })
                  // parentThis.setState({
                  //   item: {
                  //     ...item,
                  //     iof: value,
                  //     iofFormatado: formattedValue
                  //   }
                  // })
                }}
              />
            </div>
          </Col>
        )}

      <Col lg="6" xl="6" className="lancamento despesa receita investimento divida">
        <div>
          <label>Descrição</label>
          <textarea
            id="lancamentoOQue"
            // onChange={(e) => parentThis.setItemPropertyOnState('descricao', e.target.value)}
            onChange={(e) => {
              setItem({
                descricao: e.target.value
              })
              // parentThis.setState({
              //   item: {
              //     ...item,
              //     descricao: e.target.value
              //   }
              // })
            }}
            value={item?.descricao}
            style={{ padding: 20 }}
          ></textarea>
        </div>
      </Col>

      <Col lg="12" xl="12" style={{ display: 'flex', justifyContent: 'center' }}>
        <SaveButton
          disabled={!validForm}
          transaction={{
            ...item,
            // @ts-expect-error
            meioPagamento: item?.meioPagamento ?? paymentMethod
          }}
          // beforeSave={() => {
          //   if (!item) return
          //   let newItem = item
          //   if (paymentMethod && !item?.meioPagamento) {
          //     newItem = {
          //       ...item,
          //       meioPagamento: paymentMethod
          //     }
          //     return
          //   }

          //   setTransaction(newItem)
          // }}
        />
      </Col>
    </Row>
  )
}
